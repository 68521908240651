import Cookies from 'js-cookie';
// import $ from 'jquery';

$(document).ready(function () {

  //highlight cut-off sign in
  //$("input[name='company[email]']")
  // var $emailField = $(document).find("input[name='user[email]']");
  // var $passwordField = $(document).find("input[name='user[password]']");
  // $emailField.on('click', function (e) {
  //   console.log('email target lock')
  //   $(document).find('#email-signin').addClass('form-control-focus');
  //   e.stopPropagation();
  // })
  // $passwordField.on('click', function (e) {
  //   console.log('password target lock')
  //   $(document).find('#password-signin').addClass('form-control-focus');
  //   e.stopPropagation();
  // })
  // $(document).on('click', function () {
  //   console.log('remove form-control-focus')
  //   $(document).find('#email-signin').removeClass('form-control-focus')
  //   $(document).find('#password-signin').removeClass('form-control-focus')
  // })
  //

  console.log('document loaded - on sign in pages')
  var pizza = Cookies.get('pizza')
  if(pizza != undefined)
  {
    $("input[name='user[email]']").val(pizza)
  }
  var rememberMe = false;
  $("input[name=remember]").on('change', function () {
    if ($(this).is(':checked')) {
      console.log('remember me')
      rememberMe = true
    }
    else {
      console.log('forget me')
      rememberMe = false
    }
  })
  $(document).on('submit', 'form', function () {
    if(rememberMe)
    {
      var email = $("input[name='user[email]']").val()
      Cookies.set('pizza',email, {expires: 1})
    }
    $("button.loading-btn").click();
  });



  if ($(".verify-user").length) {
    $(".verify-user").on("click", ".btn-verification", function (e) {
      e.preventDefault();

      if ($(".verify-user").find(".verification-token").val() != "") {
        $("button.loading-btn").click();
        var $popup = $('#loadingModal');
        $popup.find(".error, .message").html("");
        var $form = $(".verify-user").find("form");

        $.ajax({
          type: "PUT",
          url: "/verify_user",
          data: new FormData($form[0]),
          contentType: false,
          cache: false,
          processData: false,
          success: function (result) {
            console.log("status " + result.status)
            $popup.find(".loading-row").addClass("hide");
            $popup.find(".message-row, .button-row").removeClass("hide");

            if (result.status == 200) {
              $popup.find(".message").removeClass("hide").html(result.message);
              $popup.find(".btn-confirm").addClass("hide");
              // $popup.find(".btn-redirect").removeClass("hide");

              setTimeout(function () {
                $popup.find(".btn-redirect").trigger("click");
              }, 1000);
            }
            else {
              $popup.find(".error").removeClass("hide").html(result.error);
            }
          },
          error: function (result) {
          }
        });
      }
    });

    $("#loadingModal").on("click", ".btn-redirect", function () {
      window.location.href = "/dashboard"
    });
  }
});